import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/'


Vue.use(Router);

export default new Router({
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: [{
            path: '/',
            name: 'vacations',
            component: () =>
                import ('../MainNavigation/Vacations.vue'),
            beforeEnter: (to, from, next) => {
                if (store.getters.thatAdminEmail) {
                    console.log(store.getters.thatAdminEmail);
                    next();
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },
        },
        {
            path: '/admin',
            name: 'login',
            component: () =>
                import ('../AdminNavigation/Registration.vue'),
            beforeEnter: (to, from, next) => {
                if (store.getters.thatAdminEmail) {
                    if (store.getters.isRegisteredAdmin) {
                        console.log(store.getters.isRegisteredAdmin);
                        next({
                            name: 'login-admin'
                        })
                    } else {
                        console.log('status');
                        //console.log(store.getters.isRegisteredAdmin);
                        console.log(store.getters.getRegisteredAdmins);
                        console.log(store.getters.isRegisteredAdmin);
                        next({
                            name: 'registeradmin'
                        })
                    }
                    console.log(store.getters.thatAdminEmail);
                    next();
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },

        },
        {
            path: '/adminlogin',
            name: 'login-admin',
            component: () =>
                import ('../AdminNavigation/Registration/LoginBoxed.vue'),
            beforeEnter: (to, from, next) => {
                if (store.getters.thatAdminEmail) {
                    console.log(store.getters.thatAdminEmail);
                    next();
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },

        },
        {
            path: '/adminregister',
            name: 'registeradmin',
            component: () =>
                import ('../AdminNavigation/Registration/RegisterBoxed.vue'),
            beforeEnter: (to, from, next) => {
                if (store.getters.thatAdminEmail) {
                    console.log(store.getters.thatAdminEmail);
                    next();
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },

        },
        {
            path: '/adminvacation',
            name: 'adminvacations',
            component: () =>
                import ('../AdminNavigation/Vacations.vue'),
            beforeEnter: (to, from, next) => {
                console.log(store.getters.getStatus);
                console.log(store.getters.isRegisteredAdmin);
                if (store.getters.thatAdminEmail) {
                    console.log(store.getters.getStatus);
                    console.log(store.getters.isRegisteredAdmin);


                    if (!store.getters.isRegisteredAdmin || store.getters.getStatus !== "success") {
                        next({
                            name: 'login'
                        })
                    } else {
                        console.log(store.getters.thatAdminEmail);
                        next();
                    }
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },
        },
        {
            path: '/employees',
            name: 'employees',
            component: () =>
                import ('../AdminNavigation/Employees.vue'),
            beforeEnter: (to, from, next) => {
                console.log(store.getters.getStatus);
                console.log(store.getters.isRegisteredAdmin);
                if (store.getters.thatAdminEmail) {
                    console.log(store.getters.getStatus);
                    console.log(store.getters.isRegisteredAdmin);


                    if (!store.getters.isRegisteredAdmin || store.getters.getStatus !== "success") {
                        next({
                            name: 'login'
                        })
                    } else {
                        console.log(store.getters.thatAdminEmail);
                        next();
                    }
                } else {
                    next({
                        name: "login-user"
                    });
                }
            },
        },
        {
            path: '/login',
            name: 'login-user',
            component: () =>
                import ('../MainNavigation/Login.vue'),
            beforeEnter: (to, from, next) => {
                if (store.getters.thatAdminEmail == undefined) {
                    console.log(store.getters.account);
                    next();
                } else {
                    next({
                        name: "vacations"
                    });
                }
            },
        },
    ]
})