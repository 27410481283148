<template>
    <div class="app-footer">
        <div class="app-footer__inner text-center" v-if="isAdmin">
            Copyright 2021 INCHKIEV
        </div>
        <div class="app-footer__inner_nonadmin text-center" v-else>
            Copyright 2021 INCHKIEV
        </div>
    </div>
</template>

<script>

    import {library} from '@fortawesome/fontawesome-svg-core'
    import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
    import { mapGetters } from "vuex";

    // import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faEllipsisV,
    );
    export default {
        name: "Footer",
        components: {
        },
        computed: {
            ...mapGetters({ isAdmin: "isAdmin" }),
        },
    };
</script>
