<template>
    <div class="d-flex">
        <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <!-- <div class="widget-content-left" v-if="account">
                        <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" right>
                            <span slot="button-content">
                                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <img width="42" class="rounded-circle" src="@/assets/images/avatars/1.jpg" alt="">
                                </div>
                            </span>
                            <button type="button" tabindex="0" class="dropdown-item">Menus</button>
                            <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                            <h6 tabindex="-1" class="dropdown-header">Header</h6>
                            <button type="button" tabindex="0" class="dropdown-item">Actions</button>
                            <div tabindex="-1" class="dropdown-divider"></div>
                            <button type="button" tabindex="0" class="dropdown-item">Dividers</button>
                        </b-dropdown>
                    </div> -->
                    <div class="widget-content-left  ml-3 header-user-info" v-if="account">
                        <div class="widget-heading">{{account.name}}</div>
                        <div class="widget-subheading">{{account.username}}</div>
                    </div>
                    <div class="widget-content-right header-user-info ml-3" v-if="account">
                        <b-btn v-b-tooltip.hover title="Tooltip Example" class="btn-shadow p-1" size="sm" variant="info" v-on:click="SignOutAuth">
                            <font-awesome-icon icon="sign-out-alt" class="mr-1 ml-1"/>
                        </b-btn>
                    </div>
                    <div class="widget-content-right header-user-info ml-3" v-else>
                        <b-btn v-b-tooltip.hover title="Tooltip Example" class="btn-shadow p-1" size="sm" variant="info" id="signInButton" v-on:click="SignInAuth">
                            <font-awesome-icon icon="sign-in-alt" class="mr-1 ml-1"/>
                        </b-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faAngleDown,
        faSignInAlt,
        faSignOutAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    } from '@fortawesome/free-solid-svg-icons'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faAngleDown,
        faSignInAlt,
        faSignOutAlt,
        faTrashAlt,
        faCheck,
        faFileAlt,
        faCloudDownloadAlt,
        faFileExcel,
        faFilePdf,
        faFileArchive,
        faEllipsisH,
    );
    import { PublicClientApplication }  from "@azure/msal-browser";
    export default {
        components: {
            'font-awesome-icon': FontAwesomeIcon,
        },
        data() {
            return {
                account: undefined,
                isAccount: false
            }
        },

        methods: {
            async SignInAuth() {
                // let msalConfig = this.$store.state.msalConfig;
                // this.$msalInstance = new PublicClientApplication(msalConfig);
                await this.$msalInstance
                    .loginPopup({})
                    .then(() => {
                        const myAccounts = this.$msalInstance.getAllAccounts();
                        this.account = myAccounts[0];
                        this.$emitter.emit('login', this.account);
                    })
                    .then(()=>{
                        this.$router.push({name: 'vacations'});
                    })
                    .catch(error => {
                        console.error(`error during authentication: ${error}`);
                    });
            },
            async SignOutAuth() {
                // let msalConfig = this.$store.state.msalConfig;
                // this.$msalInstance = new PublicClientApplication(msalConfig);
                await this.$msalInstance
                    .logout({})
                    .then(() => {
                        this.$emitter.emit('logout', 'logging out');
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }, 
        },

        async created() {
            this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
        },

        async mounted() {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return;
            }
            this.account = accounts[0];
            this.$emitter.emit('login', this.account);
        },
    }


</script>
