<template>
    <div class="app-sidebar sidebar-shadow" v-on:mouseover="toggleSidebarHover('add','closed-sidebar-open')" v-on:mouseleave="toggleSidebarHover('remove','closed-sidebar-open')" v-if="isAdmin">
        <div class="app-header__logo">
            <div class="logo-src"/>
            <div class="header__pane ml-auto">
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" v-on:click="toggleBodyClass('closed-sidebar')">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-sidebar-content" >
            <VuePerfectScrollbar class="app-sidebar-scroll" >
                <sidebar-menu showOneChild v-bind:menu="menu"/>
            </VuePerfectScrollbar>
        </div>

    </div>
</template>

<script>
    import {SidebarMenu} from 'vue-sidebar-menu';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import { PublicClientApplication } from '@azure/msal-browser';
    import { mapGetters } from "vuex";

    export default {
        components: {
            SidebarMenu,
            VuePerfectScrollbar
        },

        data() {
            return {
                isOpen: false,
                sidebarActive: false,
                account: undefined,
                menu: [
                    {
                        header: true,
                        title: 'User Navigation',
                    },
                    {
                        title: 'Панелі',
                        icon: 'pe-7s-rocket',
                        child: [
                            {
                                href: '/',
                                title: 'Відпустки',
                            },
                        ]
                    },
                    {
                        header: true,
                        title: 'Admin Navigation',
                    },
                    {
                        title: 'Панелі',
                        icon: 'pe-7s-light',
                        child: [
                            {
                                href: '/admin',
                                title: 'Авторизуватись',
                            },
                            {
                                href: '/adminvacation',
                                title: 'Обновить данные',
                            },
                            {
                                href: '/employees',
                                title: 'Текущие данные',
                            },
                        ]
                    },
                ],
                collapsed: true,

                windowWidth: 0,

            }
        },

        props: {
            sidebarbg: String,
        },

        computed: {
            ...mapGetters({ isAdmin: "isAdmin" }),
        },

        methods: {

            toggleBodyClass(className) {
                const el = document.body;
                this.isOpen = !this.isOpen;

                if (this.isOpen) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            toggleSidebarHover(add, className) {
                const el = document.body;
                this.sidebarActive = !this.sidebarActive;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth > '992') {
                    if (add === 'add') {
                        el.classList.add(className);
                    } else {
                        el.classList.remove(className);
                    }
                }
            },
            getWindowWidth() {
                const el = document.body;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth < '1350') {
                    el.classList.add('closed-sidebar', 'closed-sidebar-md');
                } else {
                    el.classList.remove('closed-sidebar', 'closed-sidebar-md');
                }
            },
            async getAccessToken(){
                let request = {
                  scopes: ["User.Read", "Mail.Read"],
                };
                const msalInstance = new PublicClientApplication(this.$store.state.msalConfig,);
                try {
                  let tokenResponse = await msalInstance.acquireTokenSilent(request);
                  this.$store.commit('setAccessToken', tokenResponse.accessToken);
                } catch (error) {
                    console.error( 'Silent token acquisition failed. Using interactive mode' );
                    let tokenResponse = await msalInstance.acquireTokenPopup(request);
                    console.log(`Access token acquired via interactive auth ${tokenResponse.accessToken}`)
                    this.$store.commit('setAccessToken', tokenResponse.accessToken);
                }
            }
        },

        async created() {
            this.$emitter.on('login', async function (account) {
                    this.account = account;
                    let userAccount = {email: account.username, username: account.name};
                    this.$store.commit("setUserAccount", userAccount);
                    this.$store.dispatch("getVacationForEmail", this.account.username);
                    console.log(this.account);
                    console.log('Getting storage data');
                    if(this.$store.state.accessToken == '') {
                        await this.getAccessToken();
                    }
                }.bind(this),
            ),
            this.$emitter.on('logout', () => {
                console.log('logging out');
                this.account = undefined;
            });
        },

        mounted() {
            this.$nextTick(function () {
                window.addEventListener('resize', this.getWindowWidth);

                //Init
                this.getWindowWidth()
            });
            console.log('hi, its me!');
            console.log(this.$store.getters.isRegisteredAdmin);

            // if(this.$store.getters.isRegisteredAdmin){
            //     console.log(this.$store.getters.isRegisteredAdmin);
            //     this.menu = [
            //         {
            //             header: true,
            //             title: 'User Navigation',
            //         },
            //         {
            //             title: 'Панелі',
            //             icon: 'pe-7s-rocket',
            //             child: [
            //                 {
            //                     href: '/',
            //                     title: 'Відпустки',
            //                 },
            //             ]
            //         },
            //         {
            //             header: true,
            //             title: 'Admin Navigation',
            //         },
            //         {
            //             title: 'Панелі',
            //             icon: 'pe-7s-light',
            //             child: [
            //                 {
            //                     href: '/admin',
            //                     title: 'Авторизуватись',
            //                 },
            //                 {
            //                     href: '/adminvacation',
            //                     title: 'Відпустки',
            //                 },
            //             ]
            //         },
            //     ];
            // }
            // else{
            //     this.menu = [
            //         {
            //             header: true,
            //             title: 'User Navigation',
            //         },
            //         {
            //             title: 'Панелі',
            //             icon: 'pe-7s-rocket',
            //             child: [
            //                 {
            //                     href: '/',
            //                     title: 'Відпустки',
            //                 },
            //             ]
            //         },
            //         {
            //             header: true,
            //             title: 'Admin Navigation',
            //         },
            //         {
            //             title: 'Панелі',
            //             icon: 'pe-7s-light',
            //             child: [
            //                 {
            //                     href: '/admin',
            //                     title: 'Реєстрація',
            //                 },
            //             ]
            //         },
            //     ];
            // }
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
        }
    }
</script>
