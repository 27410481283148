//import Vue from "vue";
import Axios from "axios";

export default {
    state: {
        vacations: [],
        selectedVacation: null,
        vacationsTotal: 0,
        vacation: {},
    },

    getters: {
        vacationsTotal: state => state.vacationsTotal
    },

    mutations: {
        saveVacation(state, vacation) {		// Is intended to save the new vacation
			state.vacations.push(vacation);	// Save new order
		},
		removeVacations(state) {
            if (state.vacationsTotal)
            {
                state.vacations.splice(0, state.vacationsTotal);
                state.vacationsTotal = 0;
            }
		},
        setVacationsTotal(state) {
			let total = state.vacations.length;
			state.vacationsTotal = total; 
		},
        setVacationForEmail(state, vacation) {
            state.vacation = vacation;
        },
    },

    actions: {
        async getVacations(context) {
			context.commit("removeVacations");
			context.commit("setTableLength", 0);
            (await Axios.get("vacation")).data.forEach(v => context.commit("saveVacation", v));
            context.commit("setVacationsTotal");
			const vacationsLength = context.state.vacationsTotal;
			context.commit("setTableLength", vacationsLength);
        },

        async getVacationForEmail(context, email) {
            let data = await Axios.get(`vacation/${email}`)
            let vacation = data.data
            context.commit("setVacationForEmail", vacation)
        },

        async saveVacations(context, vacations) {
            //const data = JSON.stringify(vacations)
            await Axios.post("vacation", vacations);
        },
    }
}