//import Vue from "vue";
import Axios from "axios";

export default {
    state: {
        status: '',
        jwtToken: '',
        user: {},
        userAccount: {}, // User account set by auth20 authentication
        users: [], // Массив значений email зарегистрированных админов
        adminsForRegistrations: ["v.artyukhov@inchkiev.ua", "m.shpakovich@inchkiev.ua", "i.utrysko@inchkiev.ua", "y.duka@inchkiev.ua", "a.tomchuk@inchkiev.ua",
            "m.nikonova@inchkiev.ua", "v.zhigalyova@inchkiev.ua", "m.polyakova@inchkiev.ua", "i.utrysko@inchkiev.ua"
        ], // User email set who was register as admin
        usersTotal: 0
    },

    getters: {
        //isAdmin: state => state.adminsForRegistrations.includes(state.userAccount.email),
        isRegisteredAdmin: state => state.users.includes(state.userAccount.email),
        thatAdminEmail: state => state.userAccount.email,
        thatAdminUsername: state => state.userAccount.username,
        getRegisteredAdmins: state => state.users,
        getStatus: state => state.status,
        getMyUsername: state => state.userAccount,
        getJwtToken: state => state.jwtToken
    },

    mutations: {
        authRequest(state) {
            state.status = 'loading';
        },
        authSuccess(state, jwtToken, user) {
            state.status = 'success';
            state.jwtToken = jwtToken;
            state.user = user;
        },
        authError(state) {
            state.status = 'error';
        },
        logout(state) {
            state.status = '';
            state.jwtToken = '';
        },
        saveUser(state, user) { // Is intended to save the new order or replace the existing order
            state.users.push(user);
            // let index = state.users.findIndex(o => o.id == user.id); // Find order index if exist
            // if (index == -1) {
            //     state.users.push(user); // Save new order
            // } else {
            //     console.log('smth goew wrong!');
            //     Vue.set(state.users, index, user); //Replace existing order
            // }
            console.log(state.users)
        },
        removeUsers(state) {
            state.users.splice(0, state.ordersTotal);
            state.usersTotal = 0;
        },
        setUsersTotal(state) {
            let total = state.users.length;
            state.usersTotal = total;
        },
        setUserAccount(state, userAccount) {
            state.userAccount = userAccount;
        }
    },

    actions: {
        async login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('authRequest');
                console.log(user);
                Axios.post("account", user)
                    .then(resp => {
                        const jwtToken = resp.data.token;
                        const user = resp.data.user;
                        //localStorage.setItem('jwtToken', jwtToken);
                        Axios.defaults.headers.common = { 'Authorization': `bearer ${jwtToken}` };
                        commit('authSuccess', jwtToken, user);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('authError');
                        //localStorage.removeItem('jwtToken');
                        reject(err);
                    })
            })
        },
        async register(commit, credentials) {
            await Axios.put("account", credentials);
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout');
                //localStorage.removeItem('jwtToken');
                delete Axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
        async getRegisteredUsers(context) {
            context.commit("removeUsers");
            context.commit("setTableLength", 0);
            var data = await Axios.get("account");
            var dat1 = data.data;
            console.log(data);

            console.log(dat1);
            (dat1.forEach(u => context.commit("saveUser", u)));
            context.commit("setUsersTotal");
            const arraylength = context.state.usersTotal;
            context.commit("setTableLength", arraylength);
        }
    }
}