import Vue from 'vue'
import router from './router'
import store from './store';
import Emitter from 'tiny-emitter';

import BootstrapVue from "bootstrap-vue"

import App from './App'

import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';

Vue.config.productionTip = false;
Vue.prototype.$msalInstance = {};
Vue.prototype.$emitter = new Emitter();

Vue.use(BootstrapVue);

Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);

new Vue({
    router,
    store,
    template: '<App/>',
    render: h => h(App)
}).$mount('#app')