import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import vacations from "./vacations.js";
import signinBackEnd from "./signinBackEnd.js";

Vue.use(Vuex);
Axios.defaults.baseURL = "https://vacation-inch-backend.azurewebsites.net/api/";
//Axios.defaults.baseURL = "https://vacation-inch-backend.azurewebsites.net/api/";

export default new Vuex.Store({
    modules: {
        vacations: vacations,
        signinBackEnd: signinBackEnd,
    },
    state: {
        msalConfig: {
            auth: {
                //tenant: '<your-tenant>',
                clientId: '85cf59b5-1be2-4714-b194-3e328120dd4c',
                authority: 'https://login.microsoftonline.com/2f09f4a8-2f54-4ac6-bc28-575b43c5bd58',
                redirectUri: ' https://vacation.inchkiev.ua',
                //redirectUri: 'http://localhost:8080', // It has to be configured on your Azure tenant
                // scopes: ['<your-scopes>']
            },
            cache: {
                cacheLocation: 'localStorage',
            },
            // graph: {
            //   url: '<your-graph-api-url>',
            //   scopes: '<your-graph-api-scopes>',
            //   response_type: "blob"
            // },
            // mode: "redirect"
        },
        accessToken: "",
        // data for pager
        tableType: "",
        currentPage: 1,
        pageSize: 20,
        tableLength: 0,
        fileName: "filename",
        // data for employeeVacation
    },

    getters: {
        mainTokenResponse: state => state.accessToken,

        //isLoggedIn: state => state.signin.token ? true : false,
        authStatus: state => state.signin.status,
        currentPage: state => state.currentPage,
        pageCount: state => Math.ceil(state.tableLength / state.pageSize),
        currentPageVacations: state => {
            let index = (state.currentPage - 1) * state.pageSize;
            return state.vacations.vacations.slice(index, index + state.pageSize);
        },
        employeeVacation: state => state.vacations.vacation,
        isAdmin: state => state.signinBackEnd.adminsForRegistrations.includes(state.signinBackEnd.userAccount.email),
        vacations: state => state.vacations.vacations,
    },

    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setTableLength(state, arraylength) {
            state.tableLength = arraylength;
            state.currentPage = 1;
        },
        setPageSize(state, size) {
            state.pageSize = size;
            state.currentPage = 1;
        },
        setCurrentPage(state, page) {
            state.currentPage = page;
        },
    },

    actions: {

    }
})